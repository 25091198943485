import React from 'react';
import {Grid, GridCol, GridRow} from '@sb1/ffe-grid-react';
import {Heading2, Paragraph} from '@sb1/ffe-core-react';
import {useSettings} from '~/components/felles/Settings';
import {Link} from 'react-router';
import {trackClick} from '@sparebank1/pensjon-bm-analytics';
import Logo from '~/images/Sparebank1-logo.svg';
import {MessageBox} from "@sb1/ffe-messages-react";

interface Props {
    signert: boolean;
}
const Utlogget: React.FunctionComponent<Props> = ({ signert }) => {
    const { kfb_url } = useSettings();
    return (
        <Grid>
            <GridRow>
                <GridCol center={true} >
                    <div className="ffe-header__logo">
                        <Link to="https://www.sparebank1.no/nb/bank/bedrift.html"
                            onClick={() => trackClick('utlogget: SpareBank 1 logo')}
                            aria-label={'Portalen'}
                            rel="noopener noreferrer"
                            className="ksmb-header__brand-link">
                            <img src={Logo} className="ffe-header__logo-svg" alt="SpareBank 1 logo" />
                        </Link>
                    </div>
                </GridCol>
                <GridCol center={true} className="ksmb-utlogget-grid">
                    <MessageBox type={"tips"} className="ksmb-utlogget-message-box">
                        <Heading2>Du er nå logget ut</Heading2>
                        {signert ?
                            <Paragraph>Når avtalene er etablert vil du finne dem igjen i kundeportalen.</Paragraph>
                            :
                            <Paragraph>Du kan finne igjen tilbudet hvis du logger inn igjen.</Paragraph>
                        }
                        <a href={kfb_url}>Logg inn i kundeportalen</a>
                    </MessageBox>
                </GridCol>
            </GridRow>
        </Grid>
    );
};
export default Utlogget;
