import React from 'react';
import { Heading2 } from '@sb1/ffe-core-react';
import LegacyTable from '~/components/felles/LegacyTable';
import { GridCol, GridRow } from '@sb1/ffe-grid-react';
import { Org } from './OrgSokService';
import { SecondaryButton, ButtonGroup } from '@sb1/ffe-buttons-react';
import { Link } from 'react-router';
import { trackFormStepStarted, trackClick } from '@sparebank1/pensjon-bm-analytics';
import { steg } from '~/components/steg1/Steg1';
import TrackedButtons from '~/components/tracking/TrackedButtons';

interface Props {
    data: Org[];
    nyttSok: () => void;
    setOrgnr: (_orgnr: string) => void;
}

const OrgListe: React.FunctionComponent<Props> = ({ data, nyttSok, setOrgnr }) => {

    const onClick = orgnr => {
        trackClick(`${steg.VELG_BEDRIFT}: bedrift`);
        setOrgnr(orgnr);
        return false;
    }

    const columns = [
        { key: 'orgnr', header: 'Orgnr.' },
        {
            key: 'navn', header: 'Navn', cellRender: (_value, _col, props) => {
                return (
                    <Link to={`/`} className="ffe-link-text"
                        onClick={() => onClick(props.cells.orgnr)}>
                        {`${props.cells.navn}`}
                    </Link>);
            }
        },
        { key: 'poststed', header: 'Postnr./sted' }
    ];

    React.useEffect(() => {
        trackFormStepStarted(steg.VELG_BEDRIFT);
    });

    return (
        <GridRow>
            <GridCol>
                <Heading2 lookLike={3}>Velg din bedrift i søkeresultatet</Heading2>
                <LegacyTable columns={columns} data={data} />
                <ButtonGroup ariaLabel="Knapp for å søke på nytt">
                    <TrackedButtons steg={steg.VELG_BEDRIFT.navn} knappNavn="nytt søk">
                        <SecondaryButton onClick={() => nyttSok()}>
                            Nytt søk
                        </SecondaryButton>
                    </TrackedButtons>
                </ButtonGroup>
            </GridCol>
        </GridRow>
    );
};
export default OrgListe;
