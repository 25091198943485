import React, {useContext, useEffect, useState} from 'react';

import {Grid, GridCol, GridRow} from '@sb1/ffe-grid-react';
import {DividerLine, Heading1, Heading2, Paragraph} from '@sb1/ffe-core-react';
import {BackButton, ButtonGroup, InlineExpandButton} from '@sb1/ffe-buttons-react';
import {Collapse} from '@sb1/ffe-collapse-react';
import {Spinner} from '@sb1/ffe-spinner-react';

import {FormSubmitStatus, Skjemadata, trackFormStepStarted} from '@sparebank1/pensjon-bm-analytics';

import {FormContext} from '~/Routing';
import useSkjemafeil from '~/components/tracking/useSkjemafeil';
import TrackedButtons from '~/components/tracking/TrackedButtons';
import {useErrorHandler} from '~/components/felles/ErrorHandler';
import ProduktdetaljerSkjema, {ProduktdetaljerInput} from '~/components/produkt/ProduktdetaljerSkjema';
import {ANNET_SELSKAP, flytteSelskaper} from '~/components/produkt/optionsForNedtrekkslister';
import Prisbanner from '~/components/produkt/Prisbanner';
import VisPris from '~/components/produkt/VisPris';
import {oppdaterTilbud, Pris} from '~/components/tilbud/TilbudService';
import {useNavigate, useSearchParams} from 'react-router';
import useTilbud from '~/components/tilbud/useTilbud';
import {useTrackPage} from '~/components/tracking/useTrackPage';
import {visHoySats, visUfore} from '~/components/tilbud/regler';
import {flyttedato} from "~/utils/datoUtil";
import {Tilbud} from "~/components/tilbud/Tilbud";
import {useSettings} from "~/components/felles/Settings";

const SKJEMA: Skjemadata = { navn: 'Produktspesifikasjon', type: '', skjematype: 'KSMB' };
const steg = {
    BEREGN: { navn: 'Beregn pris', nummer: 1 },
    VELG_PRODUKTDETALJER: { navn: 'Velg produktdetaljer', nummer: 2 },
    GA_VIDERE: { navn: 'Ga videre', nummer: 3 }
};

interface Props {
    endreModus: boolean;
}

function tilProduktdetaljerInput(tilbud: Tilbud): ProduktdetaljerInput {
    const avgivendeSelskap = flytteSelskaper.find(selskap => selskap.navn === tilbud.flyttesFraSelskap);
    return {
        lavSats: tilbud.lavSats,
        hoySats: tilbud.hoySats,
        uforeDekning: tilbud.uforeDekning,
        startdato: tilbud.startdato,
        flytteSelskap: !tilbud.flyttesFraSelskap ? '' : avgivendeSelskap ? avgivendeSelskap.id : ANNET_SELSKAP,
        flytteSelskapTekst: (avgivendeSelskap ? '' : tilbud.flyttesFraSelskap) ?? '',
        flyttesFraAvtalenummer: tilbud.flyttesFraAvtale ?? '',
        dagligLederInnehaverEllerStyreleder: null
    };
}

const Produktspesifikasjoner: React.FunctionComponent<Props> = ({ endreModus }) => {
    const { tilbudId, tilbud } = useTilbud();
    const [mounted, setMounted] = useState<boolean>(true);
    const [loading, setLoading] = useState<boolean>(false);
    const [beregner, setBeregner] = useState<boolean>(false);
    const [detaljerExpanded, setDetaljerExpanded] = useState<boolean>(false);
    const [pris, setPris] = useState<Pris | null>(null);
    const { gverdi } = useSettings();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [feilmelding, setFeilmelding] = useSkjemafeil('produktspesifikasjon');
    const [ produktdetaljerInput ] = useState<ProduktdetaljerInput>(tilProduktdetaljerInput(tilbud));
    const formContext = useContext(FormContext);
    const { catchError } = useErrorHandler();
    const navigate = useNavigate();
    const [ searchParams ] = useSearchParams();
    const flytting = endreModus ? !!tilbud.flyttesFraSelskap : searchParams.get('flytting') === 'true';

    useTrackPage(SKJEMA, steg.BEREGN, () => {
        setMounted(false);
    });

    useEffect(() => {
        reberegnPris().then(() => {
            trackFormStepStarted(steg.VELG_PRODUKTDETALJER);
        });
        return (() => {
            setMounted(false);
        });
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const flyttesFraSelskap = (input: ProduktdetaljerInput): string => {
        if (flytting) {
            if (input.flytteSelskap?.toString() === ANNET_SELSKAP && input.flytteSelskapTekst) {
                return input.flytteSelskapTekst;
            } else {
                const stringId = input.flytteSelskap ? input.flytteSelskap.toString() : '';
                const flytteSelskap = flytteSelskaper.find((selskap) => selskap.id === stringId);
                return flytteSelskap ? flytteSelskap.navn : '';
            }
        } else {
            return '';
        }
    };

    const oppdater = (input: ProduktdetaljerInput) => {
        Object.assign(tilbud, {
            lavSats: input.lavSats,
            hoySats: input.hoySats ?? 0,
            uforeDekning: input.uforeDekning,
            startdato: flytting ? flyttedato() : input.startdato,
            dagligLederInnehaverEllerStyreleder: input.dagligLederInnehaverEllerStyreleder,
            flyttesFraSelskap: flyttesFraSelskap(input),
            flyttesFraAvtale: input.flyttesFraAvtalenummer
        });
    };

    const oppdaterOgReberegnPris = async (input: ProduktdetaljerInput) => {
        oppdater(input);
        if (tilbud.startdato) {
            await reberegnPris();
        }
    };

    const reberegnPris = async () => {
        setBeregner(true);
        oppdaterTilbud(tilbudId, tilbud).then((resultRespons: Pris) => {
            if (mounted) {
                setBeregner(false);
                setPris(resultRespons);
                tilbud.pris = resultRespons.pris;
            }
        }).catch(error => {
            catchError(error);
        });
    };

    function utforGaVidere(input: ProduktdetaljerInput) {
        trackFormStepStarted(steg.GA_VIDERE);
        setLoading(true);
        oppdater({...input, uforeDekning: input.uforeDekning});
        formContext.setFormStatus(FormSubmitStatus.SUCCESS);
        formContext.setFerdig(true);
        oppdaterTilbud(tilbudId, tilbud).then(() => {
            if (endreModus) {
                navigate(`..`);
            } else if (input.dagligLederInnehaverEllerStyreleder) {
                navigate(`/innlogget/signering/${tilbudId}`);
            } else {
                navigate(`/oppsummering/${tilbudId}`);
            }
        }).catch(e => catchError(e));
    }

    return <>
        <Grid>
            <GridRow className={'ksmb--padding-top'}>
                <GridCol>
                    <ButtonGroup thin={true} ariaLabel="Knapp for å gå tilbake">
                        <BackButton onClick={() => navigate(-1)}>Tilbake</BackButton>
                    </ButtonGroup>
                </GridCol>
            </GridRow>
            <GridRow className={'ksmb--padding-top'}>
                <GridCol lg={{ offset: 2, cols: 8 }} center={true} className='ksmb-grid-col--padding-bottom'>
                    <Heading1>Produktspesifikasjoner</Heading1>
                </GridCol>
            </GridRow>
        </Grid>
        {pris ?
            <>
                <Prisbanner pris={pris} beregner={beregner} />
                <Grid>
                    <GridRow className={'ksmb--padding-top'}>
                        <GridCol lg={{ offset: 3, cols: 8 }} className='ksmb-grid-col--padding-bottom'>
                            <Heading2>Innskuddspensjon</Heading2>
                            <DividerLine />
                            <Grid>
                                <GridRow>
                                    <GridCol sm="6">
                                        <VisPris
                                            belop={pris.pris}
                                            beregner={beregner}
                                            noMargin={!detaljerExpanded}
                                            className='ksmb-body-paragraph--margin-top'
                                        />
                                    </GridCol>
                                    <GridCol sm="6" className={'ksmb--flex-end'}>
                                        <div className="ksmb-produktspesifikasjon-inline-button--padding">
                                            <TrackedButtons knappNavn="produktspesifikasjon: Se innskuddspensjon-detaljer">
                                                <InlineExpandButton aria-controls="innskuddspensjon-detaljer"
                                                    aria-expanded={detaljerExpanded}
                                                    isExpanded={detaljerExpanded}
                                                    onClick={() => setDetaljerExpanded(!detaljerExpanded)}>
                                                    Innskuddspensjon
                                                </InlineExpandButton>
                                            </TrackedButtons>
                                        </div>
                                    </GridCol>
                                </GridRow>
                            </Grid>
                            <Collapse isOpen={detaljerExpanded} id="innskuddspensjon-detaljer" role="region">
                                <Paragraph>
                                    {'Alle bedrifter må ha obligatorisk tjenestepensjon (OTP) for sine ansatte, med enkelte unntak. Innskuddspensjon er en spareordning hvor det årlig spares et beløp til ansattes alderspensjon, vanligvis definert som en prosentsats av lønn. Størrelsen på den fremtidige alderspensjonen avhenger av innskuddene, avkastningen og lengden på utbetalingsperioden.'}
                                </Paragraph>
                                <Paragraph>
                                    {'Bedriften kan selv bestemme hvor mange prosent av den ansattes lønn som skal spares til alderspensjon. Innskuddspensjonen inneholder også et betalingsfritak ved arbeidsuførhet.'}
                                </Paragraph>

                            </Collapse>
                            <DividerLine />
                            <ProduktdetaljerSkjema
                                initiellProduktdetaljerInput={produktdetaljerInput}
                                ansatte={tilbud.ansatte}
                                onChange={oppdaterOgReberegnPris}
                                utforGaVidere={utforGaVidere}
                                setFeilmelding={setFeilmelding}
                                loading={loading}
                                endreModus={endreModus}
                                flytting={flytting}
                                visUfore={visUfore(tilbud.ansatte)}
                                visHoysats={visHoySats(tilbud.ansatte, gverdi)}
                                pris={pris}
                                gverdi={gverdi}
                                beregner={beregner}/>
                        </GridCol>
                    </GridRow>
                </Grid>
            </>
            :
            <Grid>
                <GridRow>
                    <GridCol center={true}>
                        <Spinner large={true} loadingText="Henter pris..." />
                    </GridCol>
                </GridRow>
            </Grid>
        }
    </>
}
export default Produktspesifikasjoner;
