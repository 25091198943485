import React from 'react';
import {Grid, GridCol, GridColProps, GridRow} from "@sb1/ffe-grid-react";
import {Heading1, Heading2, Wave} from "@sb1/ffe-core-react";
import {BulletList, BulletListItem} from "@sb1/ffe-lists-react";
import FlyttingSkjema from "~/components/flyttefullmakt/FlyttefullmaktSkjema";
import {Link, useParams} from "react-router";
import {flyttedato, formatDate, forsteINesteManed} from "~/utils/datoUtil";
import {tilAnsatte} from "~/Routing";
import {useErrorHandler} from "~/components/felles/ErrorHandler";

type Params = 'orgnr' | 'antall';

const gridColLayout: Omit<GridColProps, 'ref'> = {lg:{offset: 2, cols: 8}, md:{offset: 2, cols: 8}, sm: 12, className: 'ksmb-grid-col--padding-bottom'};

const Flyttefullmakt: React.FunctionComponent = () => {
    const {orgnr, antall} = useParams<Params>();
    const {setFeilmelding} = useErrorHandler();

    if (!orgnr || !antall) {
        setFeilmelding('Det har oppstått en feil');
        return null;
    }

    return <>
        <Grid>
            <GridRow className={'ksmb--padding-top'}>
                <GridCol {...gridColLayout} center={true}>
                    <Heading1>Nå er du snart i mål</Heading1>
                </GridCol>
            </GridRow>
            <GridRow>
                <GridCol {...gridColLayout}>
                    <Heading2 lookLike={3}>
                        Informasjon om flytting
                    </Heading2>
                    <BulletList>
                        <BulletListItem>
                            Vi sender en oppsigelse til dagens pensjonsleverandør, og flytter pensjonsavtalen med ansatte og oppsparte midler til oss.
                        </BulletListItem>
                        <BulletListItem>
                            De ansatte vil bli plassert i Alderstilpasset spareprofil med aktiv, bærekraftig forvaltning.
                        </BulletListItem>
                        <BulletListItem>
                            Regler for flytting av pensjonsavtaler innebærer at det tar opp til 3 måneder før avtalen er flyttet.
                        </BulletListItem>
                        <BulletListItem>
                            Startdato hos oss er beregnet til {flyttedato()}.
                        </BulletListItem>
                        <BulletListItem>
                            Startdato hos oss forutsetter at du signerer avtalen før {formatDate(forsteINesteManed())}.
                        </BulletListItem>
                        <BulletListItem>
                            Når avtalen er etablert vil dere motta informasjon fra oss. De ansatte vil få tilsendt velkomstinformasjon om flyttingen.
                        </BulletListItem>
                    </BulletList>
                </GridCol>
                <GridCol {...gridColLayout}>
                    <Heading2 lookLike={3}>
                        Vilkår for avtalen
                    </Heading2>
                    <BulletList>
                        <BulletListItem>
                            Pris for pensjonsavtalen vil ikke være tilgjengelig før etter at avtalen er inngått.
                            Prisen vil kunne variere fra det dere betaler i dag.
                            For å få et tilbud med pris kan du <Link to={tilAnsatte(orgnr, antall, true)}>oppgi ansattopplysninger</Link>.
                        </BulletListItem>
                        <BulletListItem>
                            Du må lese gjennom vilkårene for avtalen. Vilkårene finner du her:
                            <div>
                                <a
                                    href="https://www.sparebank1.no/content/dam/SB1/vedlegg/rapporter/Pensjon/vilkar-innskuddspensjon-med-individuelt-investeringsvalg.pdf"
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    Se fullstendige vilkår (.pdf)
                                </a>
                            </div>
                        </BulletListItem>
                    </BulletList>
                </GridCol>
            </GridRow>
            <Wave color='sand-70' />
            <GridRow background={"sand-70"} className={'ksmb--padding-top'}>
                <GridCol {...gridColLayout}>
                    <FlyttingSkjema orgnr={orgnr} antallAnsatte={parseInt(antall)} />
                </GridCol>
            </GridRow>
        </Grid>
    </>
}
export default Flyttefullmakt;
